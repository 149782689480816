import React, { useMemo } from 'react';
import Layout from '../../components/layout/layout';
import Meta from '../../components/meta';
import { useStoryblok } from '../../lib/storyblok';
import { ITextPage } from '../../types/global.types';
import { richTextResolver } from '../../utils/rich-text-resolver';
import { htmlRaw } from '../../utils/utils';

const TextPage = ({ pageContext, location }) => {
	let story = pageContext.story;
	story = useStoryblok(story, location);
	const { 
		headerLink,
		a2_footer,
		headline,
		bodyText,
		title,
		description,
	}: ITextPage = story.content;

	const bodyHtml = useMemo(() => richTextResolver(bodyText), []);

	return (
		<Layout headerData={headerLink[0]} footerData={a2_footer[0]} heroData={null} className='text-page'>
			<Meta title={title} description={description} />
			<div className='box-wide'>
				<h1 className='text-page__headline headline-2'>{headline}</h1>
				<div className='text-page__body content-editor' dangerouslySetInnerHTML={htmlRaw(bodyHtml)}></div>
			</div>
		</Layout>
	);
};

export default TextPage;
